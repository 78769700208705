import { ApiStub } from '@app/desktop/v0/apiStub';
import DesktopInterfaceV1 from '@app/desktop/v1/interfaceStub';
import DesktopInterfaceV2 from '@app/desktop/v2/interfaceStub';
import { BaseError } from '@app/core/baseError';

import {
  IGenProvider, ISignProvider, IMultipleSignProvider, IKeyStoreProvider,
  IKeyStoreSelector, ICertificateSelector, IKeyGenOptions, ISecurityPolicy
} from '@app/core/interfaces';
import { DEFAULT_URLS, MIN_VERSION } from '@app/desktop/defaults';
import { IDesktopFilter } from '@app/core/model/Filter/IDesktopFilter';

export default class AlisonDesktopService implements IGenProvider, ISignProvider, IMultipleSignProvider, IKeyStoreProvider {
  private stub!: DesktopInterfaceV1 | DesktopInterfaceV2;
  private stubv1!: DesktopInterfaceV1;
  private stubv2!: DesktopInterfaceV2;
  private initializing = true;
  public version: string = '';
  public libVersion: string = '3.4.7';
  private tstSessionId: string = '';

  /*********************************************
   * Initialization
   *********************************************/

  constructor(urls: string[] =  DEFAULT_URLS) {
    this.healthCheck(urls).then(
      ({ url, version }) => {
        this.version = version;
        if (version >= MIN_VERSION) {
          this.stubv2 = new DesktopInterfaceV2(url);
          this.stub =  this.stubv2;
        } else {
          this.stubv1 = new DesktopInterfaceV1(url);
          this.stub =  this.stubv1;
        }
      },
      () => {}
    ).finally(() => {
      this.initializing = false;
    });
  }

  async initialize(args: { accessToken: string }) {
    await this.checkInitialization();
    return this.stub.initialize(args);
  }

  async isRunning(): Promise<boolean> {
    const stub = new ApiStub('');
    return stub.isRunning();
  }

  async isEnabled(): Promise<boolean> {
    const stub = new ApiStub('');
    return stub.isEnabled();
  }

  async enable(): Promise<void> {
    const stub = new ApiStub('');
    return stub.enable();
  }

  /*--------------------------------------------
   * Private
   *-------------------------------------------*/
  private async healthCheck(urls: string[]) {
    const { response, url } = await new Promise((resolve, reject) => {
      let promises: any = [];
      urls.forEach((url) => {
        const stub = new ApiStub(url);
        promises.push(
          stub.healthCheck().then((response: any) => {
            resolve({ response, url });
          }).catch(() => {})
        );
      });
      Promise.all(promises).then(() => { reject(); });
    });
    return { url, version: response.alisonComponent.currentVersion };
  }

  private async checkInitialization(): Promise<void> {
    if (this.initializing) {
      await new Promise(resolve => setTimeout(resolve, 500));
      await this.checkInitialization();
    } else if (this.stub === undefined) {
      throw new BaseError({ code: 20404, detail: 'Provider not found.' });
    }
  }

  /*********************************************
   * Cert Management (generate, install, import)
   *********************************************/

  async generateCsr(args: {
    keyStore: IKeyStoreSelector;
    options?: IKeyGenOptions;
    securityPolicy?: ISecurityPolicy;
  }) {
    await this.checkInitialization();
    return this.stub.generateCsr(args);
  }

  async installPkcs7(args: {
    keyStore: IKeyStoreSelector;
    pkcs7: string;
    securityPolicy?: ISecurityPolicy;
  }) {
    await this.checkInitialization();
    return this.stub.installPkcs7(args);
  }

  async importCertificate(args: {
    certificateToImport: ICertificateSelector;
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
  }) {
    await this.checkInitialization();
    return this.stubv2.importCertificate(args);
  }

  async initializeProfile(args: {
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
    password?: string;
    regenRecoveryKey?: boolean;
  }) {
    await this.checkInitialization();
    return this.stub.initializeProfile(args);
  }

  async newProfile(args: {
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
  }) {
    await this.checkInitialization();
    return this.stub.newProfile(args);
  }


  /*********************************************
   * Listing & Info (certificate, keytores, profiles)
   *********************************************/

  async certificateList(filters?: IDesktopFilter[]) {
    await this.checkInitialization();
    const { certificates } = await this.stub.certificateList();
    if (certificates.length > 0) {
      if (filters === undefined)
        return { certificates };
      else
        return { certificates: certificates.filter(cert => cert.matchs(filters)) };
    } else {
      return { certificates: [] };
    }
  }

  async listKeyStores() {
    await this.checkInitialization();
    return this.stub.listKeyStores();
  }

  async listProfiles() {
    await this.checkInitialization();
    return this.stub.listProfiles();
  }

  async keyStoreInfo(args: IKeyStoreSelector) {
    await this.checkInitialization();
    return this.stub.keyStoreInfo(args);
  }

  /*********************************************
   * Signature
   *********************************************/

  async getSignature(args: {
    dataToSignB64: string;
    format?: string;
    algorithm: string;
    extraParams?: string;
    signingCertificate: ICertificateSelector;
  }) {
    await this.checkInitialization();
    return this.stubv2.getSignature(args);
  }

  async getSignatures(args: {
    dataToSignB64: [string];
    format?: string;
    algorithm: string;
    extraParams?: string;
    signingCertificate: ICertificateSelector;
  }) {
    await this.checkInitialization();
    return this.stubv2.getSignatures(args);
  }

  /*********************************************
   * TST
   *********************************************/

  async createTstSession(args: {tstSessionId: string}) {
    this.tstSessionId = args.tstSessionId;
    await this.checkInitialization();
    return this.stub.tstOpen({tstServiceUrl:'https://tst.firmar.gob.ar/firmador', tstEndPoint:'/api/signatures', tstSessionId: args.tstSessionId});
  }

  async tstPassLogin(args: { pass: string, cuil: string }) {
    await this.checkInitialization();
    return this.stub.tstStep({tstEndPoint: '/firstLogin', tstBody: args, tstSessionId: this.tstSessionId});
  }

  async tstOTPLogin(args: { otp: string }) {
    await this.checkInitialization();
    return this.stub.tstStep({tstEndPoint: '/otpLogin', tstBody: args, tstSessionId: this.tstSessionId});
  }

  async tstDocSign(args: {docName:any, document: any ,pin: string }) {
    await this.checkInitialization();
    return this.stub.tstStep({tstEndPoint: '/signDocument', tstBody: args, tstSessionId: this.tstSessionId});
  }

}
